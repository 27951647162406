.timeline-dot {
    border-color: transparent;
    transition: transform .2s
}

.timeline-dot:hover {
    transform: scale(1.2);
}

.freshworks-icon {
    height: 1.5rem;
    width: 1.5rem;
}

.medianet-icon {
    height: 1.5rem;
    width: 1.5rem;
}

.srm-icon {
    height: 1.5rem;
    width: 1.5rem;
}

.sunbeam-icon {
    height: 1.5rem;
    width: 1.5rem;
}

.noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
