.intro-image {
    border-radius: 50%;
    width: 20rem;
    max-width: 100%;
    border: 1px solid white;
    // opacity: 0.9;
    background: rgba($color: #ffffff, $alpha: 0.5);
}

.divider {
    width: "80vw";
    background: rgba(0, 0, 0, 0.12);
}
