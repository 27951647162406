.app-bar-tool-bar {
    justify-content: space-between;
}

.name-header {
    display: flex;
    align-items: baseline;
}

.app-bar-buttons-container {
    display: flex;
}

.linkedin-icon {
    color: #2867B2;
    height: 2rem;
    width: auto;
}

.email-icon {
    color: #000000;;
    height: 2rem;
    width: auto;
}

.tab-panel {
    margin-bottom: 40px;;
}

.copyright-padded {
    bottom: 48px;
    position: relative;
}
